<template>
	<div>
		<!-- 头部导航 -->
		<el-affix :z-index='2001'>
			<nav-bar></nav-bar>
		</el-affix>
		<!-- 工具栏 -->
		<tool-box></tool-box>
		
		<div class="advert-content">
			<div class="title" v-text="info.title"></div>
			<div class="text" v-html="info.content"></div>
		</div>
		<!-- footer -->
		<web-footer></web-footer>
	</div>
</template>

<script type="text/javascript">
	import NavBar from '@/components/page/NavBar'
	import ToolBox from '@/components/toolbox'
	import WebFooter from '@/components/page/WebFooter'
	import {setLocalStorage,getLocalStorage,removeLocalStorage} from '@/utils/auth'
	
	export default {
		data() {
		  return {
				info: {},
		  }
		},
		components: {
		  NavBar,
		  ToolBox,
			WebFooter,
		},
		created (){
			
		},
		mounted(){
			this.info = this.$route.params?.title ? this.$route.params : getLocalStorage("advertInfo")
			setLocalStorage("advertInfo",this.info)
		},
		method: {
			
		}
	}
</script>

<style scoped lang="scss">
	#app {
		overflow: hidden;
	}
	.advert-content {
		width:1200px;
		margin:0px auto;
		margin-top: 30px;
		background-color: #FFF;
		.title {
			text-align: center;
			font-size: 30px;
			padding-top: 20px;
			padding-bottom: 45px;
			font-weight: 700;
		}
		.text {
			width: 1100px;
			margin: 0 auto;
			line-height: 30px;
			font-size: 18px;
			text-align: left;
			padding: 10px 0;
			text-indent: 36px;
			text-align: justify;
		}
	}
</style>
